body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edf3fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes invalid-input-shake {
  0% {
    transform: translateX(-1%);
  }
  50% {
    transform: translateX(1%);
  }
  100% {
    transform: translateX(0%);
  }
}

#scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#scrollable::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

Webcam {
  position: absolute;
  top: 50;
  left: 50;
  transform: translate(-50%, -50%);
}

img {
  height: 300px;
  width: 300px;
  object-fit: cover;
}